/**
** JAVASCRIPT
** Name: Slider
********************************************************************************/

import $ from 'jquery';
import 'slick-carousel';

(function() {
  'use strict';


  if($('.js-slider').length > 0) {


    /**
    ** Banner
    ****************************************/

    if($('.js-slider[data-type="banner-items"]').length > 0) {

      $('.js-slider[data-type="banner-items"]').each(function() {
        var $sliderI  = $(this),
            $sliderP  = $sliderI.parent().find('.js-slider[data-type="banner-pictures"]'),
            $items    = $sliderI.find('.js-slider-items'),
            $pictures = $sliderP.find('.js-slider-items'),
            $pager    = $sliderI.find('.js-slider-pager'),
            $current  = $sliderI.find('.js-slider-current'),
            $total    = $sliderI.find('.js-slider-total'),
            $prev     = $sliderI.find('.js-slider-prev'),
            $next     = $sliderI.find('.js-slider-next');

        // ON INIT
        $items.on('init', function(event, slick) {
          $total.html(('0' + slick.slideCount).slice(-2));
        });

        // ON UPDATE
        $items.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
          $current.html(('0' + (nextSlide + 1)).slice(-2));
        });

        // INIT PICTURES
        $pictures.slick({
          autoplay: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          mobileFirst: true
        });

        // INIT ITEMS
        $items.slick({
          autoplay: true,
          autoplaySpeed: 4000,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          appendDots: $pager,
          dotsClass: "c-banner__dots",
          mobileFirst: true,
          asNavFor: $pictures
        });

        // PREV
        $prev.on('click', function() {
          $items.slick('slickPrev');
        });

        // NEXT
        $next.on('click', function() {
          $items.slick('slickNext');
        });

      });
    }


    /**
    ** Testimonials
    ****************************************/

    if($('.js-slider[data-type="testimonials"]').length > 0) {

      $('.js-slider[data-type="testimonials"]').each(function() {
        var $slider = $(this),
            $items  = $slider.find('.js-slider-items'),
            $pager  = $slider.find('.js-slider-pager');

        $items.slick({
          autoplay: true,
          autoplaySpeed: 4000,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          appendDots: $pager,
          dotsClass: "c-testimonials__dots",
          mobileFirst: true
        });

      });

    }


    /**
    ** Partners
    ****************************************/

    if($('.js-slider[data-type="partners"]').length > 0) {

      $('.js-slider[data-type="partners"]').each(function() {
        var $slider = $(this),
            $items  = $slider.find('.js-slider-items'),
            $prev   = $slider.find('.js-slider-prev'),
            $next   = $slider.find('.js-slider-next');

        $items.slick({
          autoplay: true,
          autoplaySpeed: 4000,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
          dots: false,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
              }
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 4
              }
            }
          ]
        });

        // PREV
        $prev.on('click', function() {
          $items.slick('slickPrev');
        });

        // NEXT
        $next.on('click', function() {
          $items.slick('slickNext');
        });

      });
    }


    /**
    ** Dual
    ****************************************/

    if($('.js-slider[data-type="dual"]').length > 0) {

      $('.js-slider[data-type="dual"]').each(function() {
        var $slider = $(this),
            $main   = $slider.find('.js-slider-main .js-slider-items'),
            $aside  = $slider.find('.js-slider-aside .js-slider-items'),
            $pager  = $slider.find('.js-slider-pager'),
            $prev   = $slider.find('.js-slider-prev'),
            $next   = $slider.find('.js-slider-next');

        $main.slick({
          autoplay: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          appendDots: $pager,
          dotsClass: "c-dual__dots",
          mobileFirst: true,
          asNavFor: $aside
        });

        $aside.slick({
          autoplay: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          mobileFirst: true,
          asNavFor: $main
        });

        // PREV
        $prev.on('click', function() {
          $main.slick('slickPrev');
        });

        // NEXT
        $next.on('click', function() {
          $main.slick('slickNext');
        });

      });
    }

    /**
    ** Full screen
    ****************************************/

    if($('.js-slider[data-type="video-picture"]').length > 0) {

      $('.js-slider[data-type="video-picture"]').each(function() {
        var $slider = $(this),
            $items   = $slider.find('.js-slider-items'),
            $prev   = $slider.find('.js-slider-prev'),
            $next   = $slider.find('.js-slider-next');

        $items.slick({
          autoplay: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          mobileFirst: true,
        });

        // PREV
        $prev.on('click', function() {
          $items.slick('slickPrev');
        });

        // NEXT
        $next.on('click', function() {
          $items.slick('slickNext');
        });

      });

      /**
      * Stopping video before slide change
      **/
      $( '.js-slider[data-type="video-picture"]' ).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        if ($(this).find('.slick-current iframe').length ) {
          $(this).find('.slick-current iframe').attr('src', $('.slick-current iframe').attr('src'));
        }
    });

    }
  }

})();
