/**
** JAVASCRIPT
** Name: Sitemap
********************************************************************************/

import $ from 'jquery';

(function() {
  'use strict';

  $('.js-sitemap-toggle').on('click', function(event) {

    if($(window).outerWidth() < 720) {
      event.preventDefault();

      var $trigger = $(this),
          $target  = $trigger.parents('.js-sitemap-section');

      $target.toggleClass('is-active');
    }
  });

})();
