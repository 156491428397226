/**
** JAVASCRIPT
** Name: Toggle
********************************************************************************/

import $ from 'jquery';

(function() {
  'use strict';

  $('.js-toggle-trigger').on('click', function(event) {
    event.preventDefault();

    var $trigger = $(this),
        $target  = $trigger.parents('.js-toggle');

    if($target.data('toggle-group') != "") {
      var $group = $('.js-toggle[data-toggle-group="' + $target.data('toggle-group') + '"]');
      $group.not($target).removeClass('is-active');
    }

    $target.toggleClass('is-active');
  });

})();
