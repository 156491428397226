/**
** JAVASCRIPT
** Name: Media
********************************************************************************/

import $ from 'jquery';

(function() {
  'use strict';

  $('.js-media-veil').on('click', function() {
    var $veil  = $(this),
        $media = $veil.parents('.js-media'),
        $iframe = $media.find('iframe');

    $iframe.attr('src', $iframe.attr('src') + '&autoplay=1');
    $media.addClass('is-active');
  });

})();
