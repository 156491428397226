/**
** JAVASCRIPT
** Name: Menu
********************************************************************************/

import $ from 'jquery';

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  var $body  = $('body'),
      $items = $('.js-menu-item');


  /**
  ** Burger click
  ****************************************/

  $('.js-menu-burger').on('click', function(event) {
    event.preventDefault();
    $body.toggleClass('menu-active');
  });


  /**
  ** Link click
  ****************************************/

  $('.js-menu-link').on('click', function(event) {
    event.preventDefault();

    var $this = $(this),
        $item = $this.parents('.js-menu-item');

    $items.not($item).removeClass('is-active');
    $item.toggleClass('is-active');

    if($item.hasClass('is-active')) {
      $body.addClass('menu-item-active');
    }
    else {
      $body.removeClass('menu-item-active');
    }
  });


  /**
  ** Link keydown
  ****************************************/

  $('.js-menu-link').keydown(function(event) {
    if(event.keyCode == 13) {
      event.preventDefault();

      var $this  = $(this),
          $items = $('.js-menu-item'),
          $item  = $this.parents('.js-menu-item');

      $items.not($item).removeClass('is-accessible');
      $item.toggleClass('is-accessible');

      if($item.hasClass('is-accessible')) {
        $('#header').addClass('is-accessible');
      }
      else {
        $('#header').removeClass('is-accessible');
      }

      return false;
    }
  });

})();
